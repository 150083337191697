.news-page {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    /*max-height: 500px;*/
    /*padding: 20px;*/
}

.back-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #0056b3;
}

.news-content {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.news-title {
    font-size: 32px;
    color: #343a40;
    margin-bottom: 10px;
}

.news-date {
    font-size: 14px;
    color: #999;
    margin-bottom: 20px;
}

.news-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 5px;
    object-fit: cover;
}

.news-text {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
}
