.right-column-container {
}

.profileContainer {
    display: grid;
    grid-template-columns: 35% 1fr;
    gap: 20px;
    margin: auto;
    width: 100%;
    max-width: 1200px;
    padding-top: 10px;
}

.rightColumn {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}


.profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, #d7efff, #ffffff);
    /*padding: 20px;*/
    border-radius: 12px;
    max-width: 100%;
    height: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.avatar-container {
    width: 280px;
    height: 280px;
    border-radius: 10%;
    overflow: hidden;
    margin: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
}

.avatar {
    width: 100%;
    height: 100%;
    border-radius: 10%;
    object-fit: cover;
}

.no-avatar {
    color: #bbb;
    font-size: 14px;
}

.file-input-button {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
}

.file-input-button-label {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
}

.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 20px;
}

.button:disabled {
    cursor: not-allowed;
}

.change-card {
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.change-card-icon {
    font-size: 28px;
    color: #5a5a5a;
}

.change-card-content h4 {
    margin: 0 0 5px 0;
    font-size: 16px;
    color: #333;
}

.change-card-content p {
    margin: 0;
    font-size: 14px;
    color: #666;
}

.access-level-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.access-level-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
}


/*.access-level-card {*/
/*    flex: 1 1 200px; /*/
/*}*/

.access-level-card {
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
    padding: 25px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background-color: #d7efff;
    display: flex;
    align-items: center;
    gap: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.access-level-icon {
    font-size: 36px;
    color: #0073e6;
}

.access-level-content h3 {
    margin: 0 0 8px 0;
    font-size: 18px;
    color: #333;
}

.access-level-content p {
    margin: 0;
    font-size: 14px;
    color: #555;
}

.input-wrapper {
    margin-bottom: 15px;
}

.input-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.input-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 14px;
}

@media (max-width: 768px) {
    .profileContainer {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        gap: 15px;
    }

    .profile-card {
        max-width: 100%;
        margin: 0;
        width: 100%;
    }

    .right-column-container {
        padding-top: 20px;
    }

    .rightColumn {
        width: 100%;
    }

    .avatar-container {
        width: 200px;
        height: 200px;

    }


}
