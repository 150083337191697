@font-face {
    font-family: 'franklin-gothic';
    src: url('./assets/fonts/FranklinGothic.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Franklin Gothic', sans-serif;
    margin: 0px 0px 8px 0px;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 98vh;
}

header {
    background: #333;
    color: #fff;
    padding: 1rem;
}

header nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
}

header nav ul li {
    display: inline;
}

header nav ul li a, header nav ul li button {
    color: #fff;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
}

main {
    flex: 1;
    /*padding: 1rem;*/
}

footer {
    background: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
}
