/* Header container */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e1e1e1;
    width: 100vw;
    top: 0px;
    min-height: 75px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    margin: auto;
    width: 100vw;
    top: 0px;
    max-width: 1200px;
}

/* Navigation styles */
.nav {
    display: flex;
    align-items: center;
    flex: 1;
}

.nav-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
}

.nav-link {
    margin: 0 15px;
    text-decoration: none;
    color: #b3b3bd;
    font-size: 16px;
}

.nav-link:hover {
    text-decoration: underline;
}

/* User info section */
.user-info {
    display: flex;
    align-items: center;
}

.user-avatar {
    margin-right: 15px;
}

.user-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user-details {
    margin-right: 15px;
}

.user-name {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #000000;
    padding-left: 10px;
}

.user-role {
    font-size: 14px;
    color: #6c757d;
    margin: 0;
    text-align: left;
    padding-left: 10px;
}

/* Logout button */
.logout-button {
    background-color: #007afd;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
}

.logout-button:hover {
    background-color: #c82333;
}

.dropdown-menu {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 10px;
    z-index: 1;
}

.dropdown-item {
    background: none;
    border: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    color: #dc3545;
    border-radius: 8px;
}

.dropdown-item:hover {
    background-color: #0086ff;
}

.dropdown-menu-text {
    color: black;
}

.dropdown-menu-icon {
    color: black;
    padding-right: 10px;
}

.logout-confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-shrink: 0;
    padding-right: 20px;
}

.logout-confirmation p {
    margin: 0;
    color: black;
    font-weight: bold;
    white-space: nowrap;
}

.logout-confirmation button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    transition: background-color 0.3s;
    border-radius: 8px;
}

.logout-confirmation button:hover {
    background-color: #007afd;
}


/* Mobile styles */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        /*margin-left: 10px;*/
        /*margin-right: 20px;*/
        position: relative;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .nav {
        width: 100%;
        justify-content: space-between;
    }

    .nav-list {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        display: none;
        background-color: rgba(122, 118, 118, 0);
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;

        z-index: 1000;
        transition: max-height 0.4s ease-out, opacity 0.3s ease-out;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        border-radius: 8px;
    }

    .nav-list-info {
        background-color: rgb(231, 231, 231);
        top: 65px;
    }

    .nav-list.open {
        display: flex;
        max-height: 400px;
        opacity: 1;
        max-width: 150px;
    }

    .nav-link {
        margin: 10px 20px;
        font-size: 18px;
        color: #ffffff;
        padding: 10px 0;
        width: 100%;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        transition: background-color 0.3s ease;
        border-radius: 8px;
    }

    .nav-link-profile {
        margin: 10px 20px;
        font-size: 18px;
        color: #000000;
        padding: 10px 0;
        width: 100%;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        transition: background-color 0.3s ease;
        border-radius: 8px;
    }

    .nav-link:hover {
        color: #007bfd;
    }

    .menu-icon {
        font-size: 30px;
        cursor: pointer;
        color: #333;
        padding-left: 10px;
        transition: transform 0.3s ease;
    }

    .menu-icon:hover {
        transform: scale(1.1);
    }

    .user-info {
        width: 100%;
        justify-content: center;
        padding-right: 20px;
    }

    .user-avatar {
        margin-right: 0;
    }

    .user-details {
        margin: 0;
        text-align: center;
    }

    .user-name {
        font-size: 18px;
        color: #007afd;
        font-weight: bold;
    }

    .user-role {
        font-size: 16px;
        color: #555;
    }
}

@media (min-width: 769px) {
    .menu-icon {
        display: none;
    }

    .nav-list {
        position: static;
        flex-direction: row;
        max-height: none;
        display: flex !important;
        box-shadow: none;
    }
}
