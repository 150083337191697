.homepage {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    /*padding: 10px;*/
}

/* Carousel */
.carousel-block {
    margin: auto;
    max-width: 1200px;
    max-height: 400px;
}

.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 400px;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
}

.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
}

.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
}

.carousel-control.prev {
    left: 10px;
}

.carousel-control.next {
    right: 10px;
}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 5px;
}

.indicator {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.indicator.active {
    background-color: white;
}

/* Main page styles */
.info-news {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding-top: 10px;
    margin: auto;
    width: 100%;
    max-width: 1200px;
    min-height: 682px;
    box-sizing: border-box;
    overflow: hidden;
}

.info-block {
    flex: 0 1 100%;
    box-sizing: border-box;
}

.news-block-container {
    position: relative;
    width: 100%;
    max-height: 700px; /* Ограничение по высоте контейнера */
    overflow: visible;
}

.news-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 100%;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    transition: overflow-y 0.3s ease;
}

.news-block-container:hover .news-block {
    overflow-y: auto; /* Включает вертикальную прокрутку при наведении */
}

.news-block::-webkit-scrollbar {
    display: none;
}

.news-scroll-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 10;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    pointer-events: all;
    transition: opacity 0.3s ease;

}

/* Верхняя кнопка */
.news-scroll-up {
    top: -5px;  /* Кнопка будет видна наполовину за пределами блока */
    transform: translate(-50%, 0%);
}

/* Нижняя кнопка */
.news-scroll-down {
    bottom: -15px;  /* Кнопка будет видна наполовину за пределами блока */
    transform: translate(-50%, 0%);
}

.news-scroll-button:hover {
    opacity: 1;
}
.news-card {
    display: flex;
    align-items: flex-start;
    /*border: 1px solid #ccc;*/
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;
    cursor: pointer;
    min-height: 100px;
    transition: transform 0.3s ease; /* Плавный переход для самой карточки */
}

.news-card:hover {
    transform: scale(1.02); /* Небольшое увеличение всей карточки при наведении */
}

.news-card-image-container {
    flex-shrink: 0;
    width: 120px;
    height: 100%;
    margin-right: 10px;
    overflow: hidden; /* Скрываем часть изображения при его увеличении */
    border-radius: 5px;
}

.news-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease; /* Плавный переход для изображения */
}

.news-card-image-container:hover .news-card-image {
    transform: scale(1.1); /* Увеличение изображения при наведении */
}

.news-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.news-card-title {
    margin: 0 0 10px;
    font-size: 18px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-card-date {
    margin: 0 0 10px;
    font-size: 14px;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-card-short {
    margin: 0;
    font-size: 16px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
    max-height: 4.5em;
}


/*company info*/
.company-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 25px;
    text-align: left;
}

.company-info-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.company-info-logo {
    margin-right: 20px;
}

.company-info-logo img {
    width: 160px;
    height: auto;
    border-radius: 5%;
    object-fit: cover;
}

.company-info-title {
    flex: 1;
}

.company-info-title h2 {
    font-size: 24px;
    color: #343a40;
    margin-bottom: 10px;
}

.company-info-description {
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 20px;
    line-height: 1.5;
}

.company-info-content {
    display: flex;
    margin-top: 20px;
}

.company-info-map {
    flex: 7;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 20px;
}

.company-info-map iframe {
    width: 100%;
    border: none;
}

.company-info-contact-block {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.company-info-contact,
.company-info-address,
.company-info-phone {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.company-info-contact a,
.company-info-address-text,
.company-info-phone-text {
    margin-left: 10px;
    color: #343a40;
}

.company-info-contact a {
    text-decoration: none;
    color: #007BFF;
}

.company-info-contact a:hover {
    text-decoration: underline;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
}

.modal h2 {
    margin-top: 0;
}

.modal button {
    margin-top: 10px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .company-info-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .company-info-logo {
        margin-bottom: 10px;
    }

    .company-info-title {
        text-align: center;
    }

    .company-info-content {
        flex-direction: column;
        width: 100%;
    }

    .company-info-map {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .info-news {
        flex-direction: column;
    }

    .info-block, .news-block {
        flex: none;
        width: 100%;
    }

    .news-block-container {
        max-height: 100%;
    }

    .news-block {
        margin-top: 20px;
    }

    .news-card {
        min-height: 100px;
    }
}