.footer {

    padding: 10px 0px;
    background-color: #242a30;
    margin: auto;
    width: 100%;
    max-width: 1200px;
    border-radius: 8px;
    height: 44px;

}