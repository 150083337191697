.info-container {

}

.table-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 223px);
    margin: auto;
    width: 100%;
    max-width: 1200px;
    padding-top: 10px;
}

.table-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.table-scrollable {
    overflow-y: auto;
    flex-grow: 1;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    position: sticky;
    top: 0;
    background-color: #f1f1f1;
    z-index: 1;
    text-align: left;
}

td, th {
    padding: 8px;
    border: 1px solid #ddd;
}

.pagination-controls {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination span {
    margin: 0 5px;
    padding: 5px 10px;
}

/* Mobile styles */
@media (max-width: 768px) {
    .table-container {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 223px);
        padding-left: 0px;
    }

    .info-container {
        padding-right: 0px;
    }
}